import { FC, useCallback, useEffect, useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import {
  FormProvider,
  useForm,
  Tooltip,
  CardHeader,
  formSubmit,
} from '@fleet/shared';
import { FormField } from '@fleet/shared/form/FormField';
import {
  SelectField,
  TextField,
  TimeZoneField,
  RadioGroupField,
} from '@fleet/shared/form';
import {
  Button,
  CardContent,
  Grid,
  Stack,
  Typography,
  Divider,
  IconButton,
} from '@mui/material';
import { Icon } from '@fleet/shared';
import { useDispatch, useSelector } from 'store/utils';
import { Accordion, AccordionPanel } from '@fleet/shared/mui';
import { Stop, StopLocalization, StopPayload } from 'dto/stop';
import {
  getStop,
  createStop,
  updateStop,
  deleteStop,
  getStopsList,
  clearStop,
} from 'features/stop/stopActions';
import { StopFormLocalizations } from 'routes/Stops/StopFormLocalizations';
import { StopFormCodes } from 'routes/Stops/StopFormCodes';
import { StopFormPlatforms } from 'routes/Stops/StopFormPlatforms';
import { StopFormAttributes } from 'routes/Stops/StopFormAttributes';
import { currentStopSelector } from 'features/stop/stopSelectors';
import { useHistory } from 'react-router-dom';
import { TransAlert } from 'i18n/trans/alert';
import { TransField } from 'i18n/trans/field';
import { TransButton } from 'i18n/trans/button';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { useAlert } from 'react-alert';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';

const useStyles = makeStyles(
  () => ({
    root: {},
  }),
  {
    name: 'StopForm',
  }
);

export interface StopFormProps {
  id?: Stop['id'];
}

export const StopForm: FC<StopFormProps> = ({ id }) => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const currentStop = useSelector(currentStopSelector);
  const countryOptions = useClassificationOptions(ClassificationGroup.COUNTRY);
  const countyOptions = useClassificationOptions(ClassificationGroup.COUNTY);
  const cityOptions = useClassificationOptions(ClassificationGroup.CITY);
  const initialValues = useMemo(
    () => currentStop && currentStop,
    [currentStop]
  );
  const onSubmit = useCallback(
    (values) =>
      formSubmit(async () => {
        const v = {
          ...values,
          isSearchable: values.isSearchable == 'true',
        };
        const stop = await dispatch(
          id ? updateStop(values) : createStop(v)
        ).unwrap();

        if (stop.id) {
          history.replace(`/stops/edit/${stop.id}`);
        }

        alert.success(
          <TransAlert i18nKey={id ? 'stopUpdated' : 'stopCreated'} />
        );

        dispatch(getStopsList());
      }),
    [alert, history, dispatch, id]
  );

  const { form, handleSubmit, pristine } = useForm<StopPayload>({
    initialValues,
    onSubmit,
  });
  const { reset, restart } = form;

  const onStopDelete = useCallback(async () => {
    await dispatch(deleteStop(id!));
    alert.success(<TransAlert i18nKey="stopDeleted" />);
    history.replace('/stops');
    await dispatch(getStopsList());
  }, [alert, id, history, dispatch]);

  useEffect(() => {
    restart(currentStop ? initialValues : {});
  }, [currentStop, initialValues, restart]);

  useEffect(() => {
    if (id) {
      dispatch(getStop(id));
    } else {
      dispatch(clearStop());
    }
  }, [dispatch, id]);

  const handleReset = useCallback(() => {
    reset(initialValues);
  }, [initialValues, reset]);

  const handleGoBack = useCallback(() => {
    history.replace('/stops');
  }, [history]);

  return (
    <FormProvider form={form}>
      <CardHeader
        isLight
        title={
          <Typography variant="subtitle">
            {id ? currentStop?.name : <TransSubtitle i18nKey="newStop" />}
          </Typography>
        }
        action={
          <>
            {Boolean(false && id) && (
              <Button
                // TODO, wait for BE implementation
                // https://youtrack.tsolutions.co/issue/BR-44922#focus=Comments-58-187964.0-0, point 11
                // Can't remove created stop, API returns 405 because endpoint does not exist. Please remove this button from UI - not OK, still reproducible
                startIcon={<Icon name="trash" />}
                onClick={onStopDelete}
                color="error"
              >
                <TransButton i18nKey="delete" />
              </Button>
            )}
            <IconButton aria-label="close" onClick={handleGoBack}>
              <Tooltip content={<TransButton i18nKey="close" />} delay={500}>
                <Icon name="close" size={24} />
              </Tooltip>
            </IconButton>
          </>
        }
      />
      <CardContent
        sx={{ p: 3, pt: 2 }}
        component="form"
        onSubmit={handleSubmit}
      >
        <Grid className={classes.root} container columns={4} spacing={2}>
          <Grid item xs={1}>
            <TextField
              name="name"
              label={<TransField i18nKey="name" />}
              required
            />
          </Grid>
          <Grid item xs={1}>
            <SelectField
              name="countryId"
              label={<TransField i18nKey="country" />}
              options={countryOptions}
              required
            />
          </Grid>
          <Grid item xs={1}>
            <TimeZoneField
              name="timeZone"
              label={<TransField i18nKey="timeZone" />}
            />
          </Grid>
          <Grid item xs={1}>
            <TextField name="state" label={<TransField i18nKey="state" />} />
          </Grid>
          <Grid item xs={1}>
            <SelectField
              name="countyId"
              label={<TransField i18nKey="county" />}
              options={countyOptions}
            />
          </Grid>
          <Grid item xs={1}>
            <SelectField
              name="cityId"
              label={<TransField i18nKey="cityParish" />}
              options={cityOptions}
            />
          </Grid>
          <Grid item xs={1}>
            <TextField
              name="streetAddress"
              label={<TransField i18nKey="street" />}
            />
          </Grid>
          <Grid item xs={1}>
            <TextField
              name="zipCode"
              label={<TransField i18nKey="zipCode" />}
            />
          </Grid>
          <Grid item xs={1}>
            <TextField
              name="minTransferTimeInMinutes"
              label={<TransField i18nKey="minTransferTime" />}
            />
          </Grid>

          <Grid item xs={1}>
            <TextField name="email" label={<TransField i18nKey="email" />} />
          </Grid>
          <Grid item xs={1}>
            <TextField name="phone" label={<TransField i18nKey="phone" />} />
          </Grid>

          <Grid item xs={1}>
            <RadioGroupField
              name="isWheelChairAccessible"
              options="BOOL_ONLY"
              inline
              label={<TransField i18nKey="wheelchair" />}
            />
          </Grid>
          <Grid item xs={1}>
            <RadioGroupField
              name="isMultiLegHub"
              options="BOOL_ONLY"
              inline
              label={<TransField i18nKey="multiLeg" />}
            />
          </Grid>
        </Grid>

        <Divider sx={{ margin: '18px 0' }} />
        <Grid className={classes.root} container columns={4} spacing={2}>
          <Grid item xs={1}>
            <TextField
              name="latitude"
              label={<TransField i18nKey="latitude" />}
              type="number"
            />
          </Grid>

          <Grid item xs={1}>
            <TextField
              name="longitude"
              label={<TransField i18nKey="longitude" />}
              type="number"
            />
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          {!id ? (
            <Button onClick={() => history.replace('/stops')}>
              <TransButton i18nKey="cancel" />
            </Button>
          ) : (
            <Button disabled={pristine} onClick={handleReset}>
              <TransButton i18nKey="resetChanges" />
            </Button>
          )}
          <Button
            type="submit"
            variant="contained"
            startIcon={<Icon name={id ? 'check' : 'plus24'} />}
          >
            {id ? (
              <TransButton i18nKey="save" />
            ) : (
              <TransButton i18nKey="create" />
            )}
          </Button>
        </Stack>
      </CardContent>
      <Accordion>
        <AccordionPanel
          id="langDependantParams"
          summary={
            <Typography variant="subtitle">
              <TransSubtitle i18nKey="langDependantParams" />
              &nbsp;
              <Typography component="span" variant="body1">
                <FormField<Array<StopLocalization>>
                  name="localizations"
                  subscription={{ value: true }}
                >
                  {({ input: { value } }) => `(${value.length})`}
                </FormField>
              </Typography>
            </Typography>
          }
          disabled={!id}
        >
          {currentStop && <StopFormLocalizations />}
        </AccordionPanel>
        <AccordionPanel
          id="codes"
          disabled={!currentStop}
          summary={
            <Typography variant="subtitle">
              <TransSubtitle i18nKey="codes" />

              {currentStop && (
                <>
                  &nbsp;
                  <Typography component="span" variant="body2">
                    ({currentStop!.codes.length})
                  </Typography>
                </>
              )}
            </Typography>
          }
        >
          {currentStop && <StopFormCodes />}
        </AccordionPanel>
        <AccordionPanel
          id="platforms"
          disabled={!currentStop}
          summary={
            <Typography variant="subtitle">
              <TransSubtitle i18nKey="platforms" />

              {currentStop && (
                <>
                  &nbsp;
                  <Typography component="span" variant="body2">
                    ({currentStop!.platforms.length})
                  </Typography>
                </>
              )}
            </Typography>
          }
        >
          {currentStop && <StopFormPlatforms />}
        </AccordionPanel>
        <AccordionPanel
          id="attributes"
          disabled={!currentStop}
          summary={
            <Typography variant="subtitle">
              <TransSubtitle i18nKey="attributes" />

              {currentStop && (
                <>
                  &nbsp;
                  <Typography component="span" variant="body2">
                    ({currentStop!.attributes.length})
                  </Typography>
                </>
              )}
            </Typography>
          }
        >
          {currentStop && <StopFormAttributes />}
        </AccordionPanel>
      </Accordion>
    </FormProvider>
  );
};
