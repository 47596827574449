import i18n from 'i18next';
import I18NextLocizeBackend from 'i18next-locize-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import { localeConfigurations } from '@fleet/shared/i18n';

i18n
  .use(I18NextLocizeBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(intervalPlural)
  .init({
    fallbackLng: 'en',
    supportedLngs: Object.keys(localeConfigurations),
    load: 'currentOnly',
    interpolation: {
      escapeValue: false,
    },
    defaultNS: 'line-network-management',
    ns: ['line-network-management'],
    // debug: true,
    initImmediate: false,
    backend: {
      version: 'release_3.0.6',
      projectId: '42618d3a-3808-4dd3-b18b-ee0649d90021',
    },
  });

export default i18n;
