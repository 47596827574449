import { FC, useCallback, useMemo } from 'react';
import { useRowSelect } from 'react-table';
import {
  FormProvider,
  Icon,
  Table,
  TableColumns,
  getEditablePropsForDictionaries,
  useForm,
} from '@fleet/shared';
import { Button, Stack } from '@mui/material';
import {
  useFormTable,
  useFormTableControls,
  useIndeterminateRowSelectCheckbox,
  useRowEditActions,
} from '@fleet/shared/hooks';
import { TransTableHead } from 'i18n/trans/table';
import { TransButton } from 'i18n/trans/button';
import {
  useClassificationMap,
  useClassificationOptions,
} from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { useDispatch, useSelector } from 'store/utils';
import { currentStopSelector } from 'features/stop/stopSelectors';
import {
  createUpdateStopLocalization,
  deleteStopLocalizations,
  getStopLocalizations,
} from 'features/stop/stopActions';
import type { StopLocalization } from 'dto/stop';
import _isEmpty from 'lodash/isEmpty';
import { useAlert } from 'react-alert';
import { TransAlert } from 'i18n/trans/alert';

interface StopFormLocalizationsProps {}

export const StopFormLocalizations: FC<StopFormLocalizationsProps> = () => {
  const dispatch = useDispatch();
  const { guid: stopId, localizations } = useSelector(currentStopSelector)!;
  const { form } = useForm<{ rows: Array<StopLocalization> }>({
    initialValues: {
      rows: localizations,
    },
  });

  const languages = useClassificationOptions(ClassificationGroup.CULTURE);
  const usedLocalizations = useMemo(
    () => localizations.map(({ culture }) => culture.id),
    [localizations]
  );
  const availableCultureOptions = useMemo(
    () => languages.filter(({ value }) => !usedLocalizations.includes(value)),
    [languages, usedLocalizations]
  );

  const cultures = useClassificationMap(ClassificationGroup.CULTURE);
  const columns = useMemo<TableColumns<StopLocalization>>(
    () => [
      {
        id: 'culture.id',
        accessor: ({ culture }) => culture?.id,
        type: 'select',
        editableProps: ({ value }) =>
          getEditablePropsForDictionaries({
            value,
            availableOptions: availableCultureOptions,
            dictionary: cultures,
          }),
        Header: <TransTableHead i18nKey="language" />,
        width: '40%',
      },
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="name" />,
        width: '60%',
      },
    ],
    [availableCultureOptions, cultures]
  );

  const alert = useAlert();
  const handleUpdateRows = useCallback(
    async (value: StopLocalization) => {
      await dispatch(createUpdateStopLocalization(value)).unwrap();
      dispatch(getStopLocalizations(stopId));
      alert.success(
        <TransAlert
          i18nKey={
            value.id ? 'stopLocalizationUpdated' : 'stopLocalizationCreated'
          }
        />
      );
    },
    [alert, dispatch, stopId]
  );

  const onRowsRemove = useCallback(
    async (localizations: Array<StopLocalization>) => {
      await dispatch(
        deleteStopLocalizations(
          localizations.map((localization) => localization.id)
        )
      );
      alert.success(<TransAlert i18nKey="stopLocalizationDeleted" />);
      dispatch(getStopLocalizations(stopId));
    },
    [alert, dispatch, stopId]
  );

  const table = useFormTable<StopLocalization>(
    {
      data: localizations,
      columns,
      form,
      onRowUpdate: handleUpdateRows,
    },
    useRowSelect,
    useIndeterminateRowSelectCheckbox,
    useRowEditActions
  );

  const { addRow, removeSelectedRows } = useFormTableControls({
    table,
    form,
    removeQuery: onRowsRemove,
  });

  return (
    <FormProvider form={form}>
      <Stack direction="row" justifyContent="flex-end" sx={{ mb: 1 }}>
        <Button
          variant="text"
          color="error"
          onClick={removeSelectedRows}
          startIcon={<Icon name="delete" />}
          disabled={_isEmpty(table.state.selectedRowIds)}
        >
          <TransButton i18nKey="deleteSelected" />
        </Button>
        <Button
          variant="text"
          onClick={addRow}
          startIcon={<Icon name="plus" />}
        >
          <TransButton i18nKey="addNew" />
        </Button>
      </Stack>
      <Table
        getHeaderGroupProps={{ sx: { background: 'white' } }}
        table={table}
      />
    </FormProvider>
  );
};
