import type { FC, MouseEvent } from 'react';
import { useCallback, useMemo } from 'react';
import { useExpanded, useRowSelect } from 'react-table';
import {
  FormProvider,
  Icon,
  Table,
  TableColumns,
  useForm,
} from '@fleet/shared';
import { Button, Stack } from '@mui/material';
import {
  useFormTable,
  useFormTableControls,
  useIndeterminateRowSelectCheckbox,
  useRowEditActions,
} from '@fleet/shared/hooks';
import { TransTableHead } from 'i18n/trans/table';
import { TransButton } from 'i18n/trans/button';
import { useDispatch, useSelector } from 'store/utils';
import { currentStopSelector } from 'features/stop/stopSelectors';
import {
  createUpdateStopPlatform,
  deleteStopPlatform,
  getStopPlatforms,
} from 'features/stop/stopActions';
import { StopPlatform } from 'dto/stop';
import _isEmpty from 'lodash/isEmpty';
import { StopFormPlatformLocalizations } from 'routes/Stops/StopFormPlatformLocalizations';
import { useAlert } from 'react-alert';
import { TransAlert } from 'i18n/trans/alert';

interface StopFormPlatformsProps {}

export const StopFormPlatforms: FC<StopFormPlatformsProps> = () => {
  const dispatch = useDispatch();
  const { guid: stopId, platforms } = useSelector(currentStopSelector)!;

  const { form } = useForm<{ rows: Array<StopPlatform> }>({
    initialValues: {
      rows: platforms,
    },
  });

  const columns = useMemo<TableColumns<StopPlatform>>(
    () => [
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="name" />,
      },
      {
        accessor: 'streetAddress',
        Header: <TransTableHead i18nKey="streetAddress" />,
        editableProps: {
          required: false,
        },
      },
      {
        accessor: 'isWheelchairAccessible',
        Header: <TransTableHead i18nKey="wheelchair" />,
        type: 'checkbox',
        editableProps: {
          required: false,
        },
      },
      {
        accessor: 'localizations',
        Header: <TransTableHead i18nKey="localizations" />,
        editableProps: {
          required: false,
        },
        Cell: ({ value = [], toggleRowExpanded, row }) => (
          <>
            <Button
              variant="text"
              onClick={() => toggleRowExpanded([row.id], !row.isExpanded)}
              disabled={!row.original.id}
            >
              <TransButton
                i18nKey="edit"
                values={{ count: value.length }}
                tOptions={{ postProcess: 'interval' }}
              />
            </Button>
            {row.original.id && (
              <StopFormPlatformLocalizations
                stopId={stopId}
                rowId={row.id}
                platformId={row.original.id}
                localizations={value}
                open={Boolean(row.isExpanded)}
                toggleRowExpanded={toggleRowExpanded}
              />
            )}
          </>
        ),
      },
    ],
    [stopId]
  );

  const alert = useAlert();
  const handleUpdateRows = useCallback(
    async (value: StopPlatform) => {
      await dispatch(createUpdateStopPlatform(value));
      await dispatch(getStopPlatforms(stopId));
      alert.success(
        <TransAlert
          i18nKey={value.id ? 'stopPlatformUpdated' : 'stopPlatformCreated'}
        />
      );
    },
    [alert, dispatch, stopId]
  );
  const onRowsRemove = useCallback(
    async (platforms: Array<StopPlatform>) => {
      await dispatch(
        deleteStopPlatform(platforms.map((platform) => platform.id!))
      );
      alert.success(<TransAlert i18nKey="stopPlatformDeleted" />);
      dispatch(getStopPlatforms(stopId));
    },
    [alert, dispatch, stopId]
  );

  const table = useFormTable<StopPlatform>(
    {
      data: platforms,
      columns,
      form,
      onRowUpdate: handleUpdateRows,
    },
    useExpanded,
    useRowSelect,
    useIndeterminateRowSelectCheckbox,
    useRowEditActions
  );

  const { addRow, removeSelectedRows } = useFormTableControls({
    table,
    form,
    removeQuery: onRowsRemove,
  });

  return (
    <FormProvider form={form}>
      <Stack direction="row" justifyContent="flex-end" sx={{ mb: 1 }}>
        <Button
          variant="text"
          color="error"
          onClick={removeSelectedRows}
          startIcon={<Icon name="delete" />}
          disabled={_isEmpty(table.state.selectedRowIds)}
        >
          <TransButton i18nKey="deleteSelected" />
        </Button>
        <Button
          variant="text"
          onClick={addRow}
          startIcon={<Icon name="plus" />}
        >
          <TransButton i18nKey="addNew" />
        </Button>
      </Stack>
      <Table
        table={table}
        getHeaderGroupProps={{ sx: { background: 'white' } }}
        getRowProps={() => ({
          sx: {},
          onClick: (e: MouseEvent) => e.preventDefault(),
        })}
      />
    </FormProvider>
  );
};
