import { type FC, useCallback, useMemo } from 'react';
import { DrawerForm, type DrawerFormProps, Loadable } from '@fleet/shared';
import { Layout } from '@fleet/shared/components/Layout';
import { useSelector } from 'store/utils';
import {
  currentStopLoadingSelector,
  stopListLoadingSelector,
} from 'features/loading/loadingSelectors';
import { useHistory, useParams } from 'react-router-dom';
import { StopForm } from 'routes/Stops/StopForm';
import { StopsTable } from 'routes/Stops/StopsTable';
import { getStopsList } from 'features/stop/stopActions';
import { useDispatch } from 'react-redux';

interface StopsProps {}

export const Stops: FC<StopsProps> = () => {
  const dispatch = useDispatch();
  const currentStopLoading = useSelector(currentStopLoadingSelector);
  const stopListLoading = useSelector(stopListLoadingSelector);
  const history = useHistory();
  const { action, id } =
    useParams<{ action: 'create' | 'edit'; id?: string }>();
  const stopId = Number(id);
  const isDrawerOpen = useMemo(
    () => Boolean(action) && (action === 'edit' ? !!id : true),
    [action, id]
  );
  const handleCloseEditForm: DrawerFormProps['onClose'] = useCallback(
    async (event, reason) => {
      if (reason === 'close') {
        history.replace('/stops');
        dispatch(getStopsList());
      }
    },
    [dispatch, history]
  );
  return (
    <Loadable loading={stopListLoading}>
      <Layout
        drawer={
          <DrawerForm open={isDrawerOpen} onClose={handleCloseEditForm}>
            <Loadable loading={currentStopLoading}>
              <StopForm id={stopId} />
            </Loadable>
          </DrawerForm>
        }
      >
        <StopsTable />
      </Layout>
    </Loadable>
  );
};
